import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { showMessage } from '../common.slice';

const initialState = {
  couponLoading: false,
  createCouponData: {},
  couponTypeAvailableData: {},
  couponTypeBookingData: {},
  couponTypeAssignedData: {},
  couponAgentData: {},
};

// export const getHoldPeriod = createAsyncThunk(
//   'admin/get-hold-period',
//   (_id, { dispatch }) => {
//     return new Promise((resolve, reject) => {
//       axios
//         .get(`api/booking/${_id}/hold_period`)
//         .then(res => {
//           if (res.data.err === 0) {
//             if (res.data.data) {
//               resolve(res.data.data);
//             } else {
//               resolve([]);
//             }
//           } else {
//             dispatch(showMessage({ message: res.data.msg }));
//             reject();
//           }
//         })
//         .catch(error => {
//           dispatch(showMessage({ message: error.response.data.msg }));
//           reject(error);
//         });
//     });
//   },
// );

// export const getPostedNotes = createAsyncThunk(
//   'admin/get-posted-notes-ac',
//   (_id, { dispatch }) => {
//     return new Promise((resolve, reject) => {
//       axios
//         .get(`api/booking/${_id}/posted_notes`)
//         .then(res => {
//           if (res.data.err === 0) {
//             if (res.data.data) {
//               resolve(res.data.data);
//             } else {
//               resolve([]);
//             }
//           } else {
//             dispatch(showMessage({ message: res.data.msg }));
//             reject();
//           }
//         })
//         .catch(error => {
//           dispatch(showMessage({ message: error.response.data.msg }));
//           reject(error);
//         });
//     });
//   },
// );

export const createCoupon = createAsyncThunk(
  'admin/create-couons-as',
  (coupons, { dispatch }) => {
    return new Promise((resolve, reject) => {
      const payload = {
        booking_id: coupons.booking_id,
        coupon_id: coupons.coupon_id,
        type: coupons.type,
      };
      axios
        .post('api/coupons', payload)
        .then(res => {
          if (res.data.err === 0) {
            // dispatch(
            //   showMessage({ message: res.data.msg, varient: 'success' }),
            // );
            resolve(res.data?.data);
            coupons.handleValidCommon(false);
          } else {
            dispatch(showMessage({ message: res.data.msg }));
            reject();
            coupons.handleValidCommon(false);
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.response.data.msg }));
          reject(error);
          if (error.response.data.msg.includes('invalid coupon')) {
            coupons.handleValidCommon(true);
          }
        });
    });
  },
);

export const couponTypeAvailable = createAsyncThunk(
  'admin/create-coupon-available-as',
  (coupons, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .post('api/coupons', coupons)
        .then(res => {
          if (res.data.err === 0) {
            // dispatch(
            //   showMessage({ message: res.data.msg, varient: 'success' }),
            // );
            resolve(res.data?.data);
          } else {
            dispatch(showMessage({ message: res.data.msg }));
            reject();
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.response.data.msg }));
          reject(error);
        });
    });
  },
);

export const couponTypeBooking = createAsyncThunk(
  'admin/cr-coupon-bookings-as',
  (coupons, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .post('api/coupons', coupons)
        .then(res => {
          if (res.data.err === 0) {
            // dispatch(
            //   showMessage({ message: res.data.msg, varient: 'success' }),
            // );
            resolve(res.data?.data);
          } else {
            dispatch(showMessage({ message: res.data.msg }));
            reject();
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.response.data.msg }));
          reject(error);
        });
    });
  },
);

export const couponTypeAssigned = createAsyncThunk(
  'admin/cr-coupon-assigned-as',
  (coupons, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .post('api/coupons', coupons)
        .then(res => {
          if (res.data.err === 0) {
            // dispatch(
            //   showMessage({ message: res.data.msg, varient: 'success' }),
            // );
            resolve(res.data?.data);
          } else {
            dispatch(showMessage({ message: res.data.msg }));
            reject();
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.response.data.msg }));
          reject(error);
        });
    });
  },
);

export const couponAgentDataApi = createAsyncThunk(
  'admin/cr-coupon-agent-data-as',
  (agent, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/bpe-user/ifAgent', agent)
        .then(res => {
          if (res.data.err === 0) {
            // dispatch(
            //   showMessage({ message: res.data.msg, varient: 'success' }),
            // );
            resolve(res.data?.data);
          } else {
            dispatch(showMessage({ message: res.data.msg }));
            reject();
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.response.data.msg }));
          reject(error);
        });
    });
  },
);

export const dateConfimedApi = createAsyncThunk(
  'admin/create-date-confimed-api-assd',
  (props, { dispatch }) => {
    const { _id, Data } = props;
    return new Promise((resolve, reject) => {
      axios
        .post(`api/booking/${_id}/date_confirmed`, Data)
        .then(res => {
          if (res.data.err === 0) {
            dispatch(
              showMessage({ message: res.data.msg, varient: 'success' }),
            );
            resolve(res.data);
          } else {
            dispatch(showMessage({ message: res.data.msg }));
            reject();
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.response.data.msg }));
          reject(error);
        });
    });
  },
);

export const productsList = createAsyncThunk(
  'admin/product-list-tr',
  (_id, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .post('api/products_list', _id)
        .then(res => {
          if (res.data.err === 0) {
            dispatch(
              showMessage({ message: res.data.msg, varient: 'success' }),
            );
            resolve(res?.data?.data);
          } else {
            dispatch(showMessage({ message: res.data.msg }));
            reject();
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.response.data.msg }));
          reject(error);
        });
    });
  },
);

export const productsForDateApi = createAsyncThunk(
  'admin/products-for-date-sg',
  (products, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .post('api/products_for_date', products)
        .then(res => {
          if (res.data.err === 0) {
            dispatch(
              showMessage({ message: res.data.msg, varient: 'success' }),
            );
            resolve(res.data);
          } else {
            dispatch(showMessage({ message: res.data.msg }));
            reject();
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.response.data.msg }));
          reject(error);
        });
    });
  },
);

export const placesTempApi = createAsyncThunk(
  'admin/places-temp-api-ggh',
  (props, { dispatch }) => {
    const { data, _id } = props;
    return new Promise((resolve, reject) => {
      axios
        .post(`api/booking/${_id}/places_temp`, data)
        .then(res => {
          if (res.data.err === 0) {
            dispatch(
              showMessage({ message: res.data.msg, varient: 'success' }),
            );
            resolve(res.data?.data);
          } else {
            dispatch(showMessage({ message: res.data.msg }));
            reject();
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.response.data.msg }));
          reject(error);
        });
    });
  },
);

export const priceRulesApi = createAsyncThunk(
  'admin/price-rule-api',
  (_id, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .post('api/price_rules')
        .then(res => {
          if (res.data.err === 0) {
            dispatch(
              showMessage({ message: res.data.msg, varient: 'success' }),
            );
            resolve(res.data);
          } else {
            dispatch(showMessage({ message: res.data.msg }));
            reject();
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.response.data.msg }));
          reject(error);
        });
    });
  },
);

export const paymentBalance = createAsyncThunk(
  'admin/payment-balance-dfg',
  (props, { dispatch }) => {
    const { data, _id } = props;
    return new Promise((resolve, reject) => {
      axios
        .post(`api/booking/${_id}/balance`, data)
        .then(res => {
          if (res.data.err === 0) {
            dispatch(
              showMessage({ message: res.data.msg, varient: 'success' }),
            );
            resolve(res.data);
          } else {
            dispatch(showMessage({ message: res.data.msg }));
            reject();
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.response.data.msg }));
          reject(error);
        });
    });
  },
);

export const getInvoiceBooking = createAsyncThunk(
  'admin/get-invoice-booking-d',
  (_id, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`api/get_invoice/${_id}`)
        .then(res => {
          if (res.data.err === 0) {
            if (res.data?.data) {
              resolve(res.data?.data);
            } else {
              resolve([]);
            }
          } else {
            dispatch(showMessage({ message: res.data.msg }));
            reject();
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.response.data.msg }));
          reject(error);
        });
    });
  },
);

export const orderProductSlice = createSlice({
  name: 'orderProduct',
  initialState,
  reducers: {
    setOrderProductLoading: (state, action) => {
      state.couponLoading = action.payload;
    },
    setIsOrderProductUpdated: (state, action) => {
      state.isCouponUpdated = action.payload;
    },
  },
  extraReducers: {
    // [getHoldPeriod.pending]: state => {
    //   state.couponLoading = true;
    // },
    // [getHoldPeriod.rejected]: state => {
    //   state.gholdPeriodData = {};
    //   state.couponLoading = false;
    // },
    // [getHoldPeriod.fulfilled]: (state, action) => {
    //   state.gholdPeriodData = action.payload;
    //   state.couponLoading = false;
    // },
    // [getPostedNotes.pending]: state => {
    //   state.couponLoading = true;
    // },
    // [getPostedNotes.rejected]: state => {
    //   state.getPostedNotesData = {};
    //   state.couponLoading = false;
    // },
    // [getPostedNotes.fulfilled]: (state, action) => {
    //   state.getPostedNotesData = action.payload;
    //   state.couponLoading = false;
    // },
    [createCoupon.pending]: state => {
      state.isCouponUpdated = false;
      state.couponLoading = true;
    },
    [createCoupon.rejected]: state => {
      state.createCouponData = {};
      state.isCouponUpdated = false;
      state.couponLoading = false;
    },
    [createCoupon.fulfilled]: (state, action) => {
      state.createCouponData = action.payload;
      state.isCouponUpdated = true;
      state.couponLoading = false;
    },
    [couponTypeAvailable.pending]: state => {
      state.isCouponUpdated = false;
      state.couponLoading = true;
    },
    [couponTypeAvailable.rejected]: state => {
      state.couponTypeAvailableData = {};
      state.isCouponUpdated = false;
      state.couponLoading = false;
    },
    [couponTypeAvailable.fulfilled]: (state, action) => {
      state.couponTypeAvailableData = action.payload;
      state.isCouponUpdated = true;
      state.couponLoading = false;
    },
    [couponTypeBooking.pending]: state => {
      state.couponLoading = true;
    },
    [couponTypeBooking.rejected]: state => {
      state.couponTypeBookingData = {};
      state.couponLoading = false;
    },
    [couponTypeBooking.fulfilled]: (state, action) => {
      state.couponTypeBookingData = action.payload;
      state.couponLoading = false;
    },
    [couponTypeAssigned.pending]: state => {
      state.couponLoading = true;
    },
    [couponTypeAssigned.rejected]: state => {
      state.couponTypeAssignedData = {};
      state.couponLoading = false;
    },
    [couponTypeAssigned.fulfilled]: (state, action) => {
      state.couponTypeAssignedData = action.payload;
      state.couponLoading = false;
    },
    [couponAgentDataApi.pending]: state => {
      state.couponLoading = true;
    },
    [couponAgentDataApi.rejected]: state => {
      state.couponAgentData = {};
      state.couponLoading = false;
    },
    [couponAgentDataApi.fulfilled]: (state, action) => {
      state.couponAgentData = action.payload;
      state.couponLoading = false;
    },
    [dateConfimedApi.pending]: state => {
      // state.isCouponUpdated = false;
      state.couponLoading = true;
    },
    [dateConfimedApi.rejected]: state => {
      // state.isCouponUpdated = false;
      state.couponLoading = false;
    },
    [dateConfimedApi.fulfilled]: (state, action) => {
      // state.isCouponUpdated = true;
      state.couponLoading = false;
    },
    [productsList.pending]: state => {
      state.couponLoading = true;
    },
    [productsList.rejected]: state => {
      state.productsListData = [];
      state.couponLoading = false;
    },
    [productsList.fulfilled]: (state, action) => {
      state.productsListData = action.payload;
      state.couponLoading = false;
    },
    [productsForDateApi.pending]: state => {
      state.couponLoading = true;
    },
    [productsForDateApi.rejected]: state => {
      // state.productsListData = [];
      state.couponLoading = false;
    },
    [productsForDateApi.fulfilled]: (state, action) => {
      // state.productsListData = action.payload;
      state.couponLoading = false;
    },
    [placesTempApi.pending]: state => {
      state.couponLoading = true;
    },
    [placesTempApi.rejected]: state => {
      state.placesTempData = [];
      state.couponLoading = false;
    },
    [placesTempApi.fulfilled]: (state, action) => {
      state.placesTempData = action.payload;
      state.couponLoading = false;
    },
    [priceRulesApi.pending]: state => {
      state.couponLoading = true;
    },
    [priceRulesApi.rejected]: state => {
      // state.productsListData = [];
      state.couponLoading = false;
    },
    [priceRulesApi.fulfilled]: (state, action) => {
      // state.productsListData = action.payload;
      state.couponLoading = false;
    },
    [paymentBalance.pending]: state => {
      state.couponLoading = true;
    },
    [paymentBalance.rejected]: state => {
      state.paymenrBalanceData = {};
      state.couponLoading = false;
    },
    [paymentBalance.fulfilled]: (state, action) => {
      state.paymenrBalanceData = action.payload;
      state.couponLoading = false;
    },
    [getInvoiceBooking.pending]: state => {
      state.couponLoading = true;
    },
    [getInvoiceBooking.rejected]: state => {
      state.getInvoiceData = {};
      state.couponLoading = false;
    },
    [getInvoiceBooking.fulfilled]: (state, action) => {
      state.getInvoiceData = action.payload;
      state.couponLoading = false;
    },
  },
});

export const { setOrderProductLoading, setIsOrderProductUpdated } =
  orderProductSlice.actions;

export default orderProductSlice.reducer;
