import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  messageOptions: 'success added',
  varient: '',
  showMessage: false,
  errorMessage: [],
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    showMessage: (state, action) => {
      state.message = action.payload.message;
      state.varient = action.payload.varient;
      state.showMessage = true;
    },
    hideMessage: (state, action) => {
      state.showMessage = false;
    },
    seterrorMessage: (state, action) => {
      state.errorMessage = action.payload.errorMessage;
    },
  },
  extraReducers: {},
});
export const { showMessage, hideMessage, seterrorMessage } =
  commonSlice.actions;
export default commonSlice.reducer;
